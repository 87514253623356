<template>
  <div class="bg-white h-auto w-full">
    <SignUpForm
      @registerEvent="handleRegister"
      :proccessingSignup="proccessingSignup"
      :showModal="showModal"
      @update:showModal="showModal = $event"
      :emailProp="email"
      class="m-auto"
    />
    <ContactFooter class="mt-8" />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import jwt_decode from "jwt-decode";

import SignUpForm from "@/components/auth/SignUpForm.vue";
import ContactFooter from "@/components/general/ContactFooter.vue";
import { createClient } from "@/services/stripe/stripe.js";
import { createFreeTrialUserPaymentInstance } from "@/services/user/user.js";

import { triggerFormSubmissionEvent } from "@/services/gtmTracker/gtmTracker.js";

const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();

const email = ref(null);
const code = ref(null);
const proccessingSignup = ref(false);
const showModal = ref(false);

onMounted(() => {
  const { data, rc } = route.query;

  if (rc) {
    code.value = rc;
  }

  if (data) {
    const decoded = jwt_decode(data);
    email.value = decoded.email;
    code.value = decoded.code;
  }
});

const registerNewUser = async (data) =>
  store.dispatch("user/registerNewUser", data);

async function handleRegister(data) {
  try {
    proccessingSignup.value = true;
    data["registrationCode"] = code.value ?? " ";
    await registerNewUser(data);

    sessionStorage.setItem("parentEmail", data.email);
    await createStripeClient({
      email: data.email,
      fullName: `${data.firstName} ${data.lastName}`,
      phoneNumber: data.phoneNumber,
      isFreeTrial: data.isFreeTrial,
      locationName: data.locationName,
    });
  } catch (err) {
    if (err.message === "Email already exists. Please try again.") {
      showModal.value = true;
    } else {
      toast.error(err.message);
    }
    proccessingSignup.value = false;
  }
}

async function createStripeClient({
  email,
  fullName,
  phoneNumber,
  isFreeTrial,
  locationName,
}) {
  if (isFreeTrial) {
    await createFreeTrialUserPaymentInstance();
    router.push({
      name: "Register players",
      query: {
        n: 1,
        registrationMethod: "free-trial",
      },
    });
  } else {
    const clientResponse = await createClient(email, fullName);
    if (clientResponse.success) {
      triggerFormSubmissionEvent(email, phoneNumber);  
      return router.push({
        name: "Check Out",
        query: {
          customerId: clientResponse.data.customer.id,
          c: code.value,
          email,
          phoneNumber,
          locationName,
        },
      });
    }
  }
}
</script>
